// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";
import MKTypography from "../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";

// translations
import { useTranslation } from "react-i18next";

//email client
import emailjs from '@emailjs/browser';
import { useState } from "react";
import Loading from "../../components/Loading";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [errors, setErrors] = useState({});
  
  const validate = (name, email, message) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name.trim()) errors.name = "Name is required";
    if (!email.trim()) errors.email = "Email is required";
    else if (!emailRegex.test(email)) errors.email = "Invalid email format";
    if (!message.trim()) errors.message = "Message cannot be empty";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = (e) => {
    const name = e.target[0].value;
    const email = e.target[1].value;
    const message = e.target[2].value;
    e.preventDefault();
    if (!validate(name, email, message)) return;
    setLoading(true);
    emailjs
      .send(
        'service_v3sm9rp', 
        'template_gayn2df',
        {message: `NAME: ${e.target[0].value}, EMAIL: ${e.target[1].value}, MESSAGE: ${e.target[2].value}`},
        'Ny3tVMNBsnnIPwoOp'
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setLoading(false)
          setMessageError(false);
          setMessageSent(true);
        },
        (error) => {
          console.log('Failed to send email:', error.text);
          setLoading(false);
          setMessageError(true);
        }
      );
  };
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 600 })
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{t('contact-us')}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MKBox variant="gradient" bgColor="mixed" shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          transparent
          relative
          light
          center
        />
      </MKBox>
      { !loading ? <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ justifyContent: "center", alignItems: "center", backgroundImage: "url(https://drrv4375usi92.cloudfront.net/images/itpr-digital-marketing-contact-us-mobile.webp)", backgroundPosition: 'center', backgroundSize: 'cover' }}
          />
          <MKBox
            display={{ xs: "flex", lg: "none" }}
            width="calc(100% - 2rem)"
            height="calc(40vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            mb={0}
            sx={{ backgroundImage: "url(https://drrv4375usi92.cloudfront.net/images/itpr-marketing-agency-contact-us-page-mobile_animation.gif)", backgroundSize: "cover", backgroundPosition: "center" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 5, sm: 10, md: 10 }}
            mb={{ xs: 5, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                {!messageSent ? t("contact-us") : t("contact-us-page.contact-us-sent")}
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                {!messageSent ? t("contact-us-page.description") : t("contact-us-page.contact-us-sent-message")}
              </MKTypography>
              { !messageSent ? <MKBox width="100%" component="form" onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label={t("contact-us-page.fullName")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={() => setErrors({ ...errors, name: null })}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label={t("contact-us-page.email")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={() => setErrors({ ...errors, email: null })}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label={t("contact-us-page.message")}
                      placeholder={t("contact-us-page.placeholder")}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      onChange={() => setErrors({ ...errors, message: null })}
                      error={!!errors.message}
                      helperText={errors.message}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info" onCli>
                    {t("send-message")}
                  </MKButton>
                </Grid>
              </MKBox>
            : <></>}
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      : <Loading />}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
