// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "../../../examples/Cards/ReviewCards/DefaultReviewCard";

// translation
import { useTranslation } from "react-i18next";

// media query
import { useMediaQuery } from "react-responsive";

const Information = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <MKBox component="section" py={isMobile ? 3 : 5}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant={isMobile ? "h3Small" : "h3"} >{t('informations-landing.title-first')}</MKTypography>
          <MKTypography variant={isMobile ? "h3Small" : "h3"} color="info" textGradient mb={2}>
            {t('informations-landing.title-second')}
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            {t('informations-landing.description')}
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              image="https://drrv4375usi92.cloudfront.net/images/testimonial-man-itpr-marketing-agency-first.webp"
              name="Lucas Muller"
              date="1 month ago"
              review="This agency is outstanding! Their expertise and thorough understanding of my needs helped me reach more clients and improve my practice efficiently."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              image="https://drrv4375usi92.cloudfront.net/images/testimonial-man-itpr-marketing-agency-third.webp"
              name="Bogdan Stojiljkovic"
              date="3 weeks ago"
              review="This agency has been a game-changer for our music label! They created innovative campaigns that captured our artists’ unique styles and reached a wider audience. Their creative strategies have significantly boosted our brand's visibility in the industry."
              rating={5}
              color="info"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              image="https://drrv4375usi92.cloudfront.net/images/testimonial-man-itpr-marketing-agency-second.webp"
              name="Melinda Roys"
              date="2 months ago"
              review="This agency truly understands our industry! They crafted a marketing strategy that perfectly suited our needs, helping us connect with more clients and build trust in the market. Their work has been invaluable to our growth."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid container spacing={3} justifyContent="center">
        <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src="https://drrv4375usi92.cloudfront.net/images/itpr-marketing-agency-testimonial-happy-plugs.webp"
              alt="Happy Plugs"
              width="100%"
              opacity={0.6}
              height="50px"
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src="https://drrv4375usi92.cloudfront.net/images/itpr-marketing-agency-testimonials-mona-logo.webp" alt="ITPR Digital Agency Clients: Mona" width="100%" height="40px" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src="https://drrv4375usi92.cloudfront.net/images/itpr-marketing-agency-testimonials-smexx.webp" alt="ITPR Digital Marketing Agency Clients: SMEXX" width="100%" height="40px" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src="https://drrv4375usi92.cloudfront.net/images/itpr-marketing-agency-testimonial-fit-net.webp" alt="ITPR Digital Marketing Agency Clients: FITNET" width="100%" height="50px" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src="https://drrv4375usi92.cloudfront.net/images/itpr-marketing-agency-comtrade.webp" alt="ITPR Digital Marketing Agency Clients: Comtrade" width="100%" opacity={0.6} height="50px" />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
