// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "../../../examples/Cards/RotatingCard";
import RotatingCardFront from "../../../examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../../examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";

// translation
import { useTranslation } from 'react-i18next';

// media query
import { useMediaQuery } from "react-responsive";

const Information = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 600 })
  return (
    <MKBox component="section" py={6} my={6} margin={0}>
      <Container>
        <Grid container item xs={12} spacing={0} pa alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }} paddingBottom={isMobile ? "50px" : ""}>
            <RotatingCard>
              <RotatingCardFront
                image={"https://drrv4375usi92.cloudfront.net/images/itpr-digital-marketing-services-landing-page-second-image.webp"}
                icon="touch_app"
                title={
                  <>
                    {t("rotate-card-landing-front.title-first")}
                    <br />
                    {t("rotate-card-landing-front.title-second")}
                  </>
                }
                description={t("rotate-card-landing-front.description")}
              />
              <RotatingCardBack
                image={"https://drrv4375usi92.cloudfront.net/images/itpr-digital-marketing-services-landing-page.webp"}
                title={
                  <>
                    {t("rotate-card-landing-back.title-first")}
                    <br />
                    {t("rotate-card-landing-back.title-second")}
                  </>
                }
                description={t("rotate-card-landing-back.description")}
                action={{
                  type: "internal",
                  route: "/contact-us",
                  label: t('contact-us'),
                  color: "info",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="insert_chart"
                  title={t('work-sections-landing.strategic-marketing.title')}
                  description={t('work-sections-landing.strategic-marketing.description')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="notifications_active"
                  title={t('work-sections-landing.advertising-campaigns.title')}
                  description={t('work-sections-landing.advertising-campaigns.description')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="supervisor_account"
                  title={t('work-sections-landing.management-consulting.title')}
                  description={t('work-sections-landing.management-consulting.description')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="public"
                  title={t('work-sections-landing.public-relations.title')}
                  description={t('work-sections-landing.public-relations.description')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
