import { useMediaQuery } from 'react-responsive';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// translation
import { useTranslation } from 'react-i18next';

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import DefaultInfoCard from '../../examples/Cards/InfoCards/DefaultInfoCard';

// Material Kit 2 React examples
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";

// routes
import footerRoutes from "../../footer.routes";
import routes from "../../routes";

// Component
import Download from '../Presentation/sections/Download';
import { Helmet } from 'react-helmet';

function SolutionsComponent() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{t('solutions-head-text-1')}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MKBox variant="gradient" bgColor="mixed" shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact-us",
            label: t('contact-us'),
            color: "info",
          }}
          transparent
          relative
          light
          center
        />
      </MKBox>
      <MKBox
        minHeight="75vh"
        width="100"
        sx={{
          backgroundImage: isMobile ? `url(https://drrv4375usi92.cloudfront.net/images/itpr-solutions-page-mobile.webp)` : `url(https://drrv4375usi92.cloudfront.net/images/itpr-solutions-page-cover-big.webp)`,
          backgroundSize: "cover",
          backgroundPosition: isMobile ? "center" : "top",
          display: "grid",
          placeItems: isMobile ? "end" : "center",
          paddingBottom: isMobile ? "200px" : "0",
        }}
        xs={0}
      >
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="start" mx="auto">
            <MKTypography
              variant={isMobile ? "landingFirstSmall" : "landingFirstBig"}
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
              textTransform="uppercase"
              backgroundColor="rgba(26,115,232,0.50)"
              fontWeight="bold"
              paddingTop="5px"
              paddingBottom="5px"
              borderRadius="10px"
              className="slide-zoom-fade"
            >
              {t("solutions-head-text-1")}
            </MKTypography>
            <MKTypography
              variant={isMobile ? "solutionsSecondSmall" : "landingSecondBig"}
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
              textTransform="uppercase"
              fontWeight="bold"
              paddingTop="2px"
              paddingBottom="20px"
              borderRadius="10px"
              className="slide-zoom-fade"
            >
              {t("solutions-head-text-2")}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container paddingTop={0} style={{ paddingBottom: '15px' }} sx={{ paddingTop: 0 }}>
          <Grid container item xs={12} justifyContent="center" mx="auto" paddingTop={0} style={{ paddingBottom: 0, paddingTop: 0}}>
            <Grid container justifyContent="center" py={6} paddingTop="15px" paddingBottom="10px">
              <Grid item xs={12} md={8} mx={{ xs: "auto", sm: 6, md: 1 }}>
                <MKBox display="flex" justifyContent={!isMobile ? "center" : "space-between"} alignItems="center" mb={1}>
                  <MKTypography variant="contentTitle">{t("solutions-main-text-title")}</MKTypography>
                </MKBox>
                <MKTypography variant="body1" fontWeight="light" color="text">
                  {t("solutions-main-text")}
                </MKTypography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container >
          <Grid container item xs={12} md={8} mx={{ xs: "auto", sm: 6, md: "auto" }} style={{ paddingLeft: isMobile ? 0 : '25px', paddingBottom: isMobile ? "50px" : "30px"}} justifyContent={"space-between"} alignItems="center">
            <Grid item xs={12} md={12}>
              <Grid container justifyContent={"center"} spacing={3}>
                <Grid item xs={12} md={6} justifyContent={"center"}>
                  <DefaultInfoCard
                    spacing={10}
                    icon="insert_chart"
                    title={t('work-sections-landing.strategic-marketing.title')}
                    description={t('work-sections-landing.strategic-marketing.description')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="notifications_active"
                    title={t('work-sections-landing.advertising-campaigns.title')}
                    description={t('work-sections-landing.advertising-campaigns.description')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="supervisor_account"
                    title={t('work-sections-landing.management-consulting.title')}
                    description={t('work-sections-landing.management-consulting.description')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="public"
                    title={t('work-sections-landing.public-relations.title')}
                    description={t('work-sections-landing.public-relations.description')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Download />
      </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SolutionsComponent;
