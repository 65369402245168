import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import './App.css';
import Presentation from './pages/Presentation';
import theme from './assets/theme';
import './i18n'; 
import routes from './routes';
import ContactUs from './pages/ContactUs';
import { useTranslation } from 'react-i18next'; 
import { useSearchParams } from 'react-router-dom';

const App = () => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation(); 
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const lng = searchParams.get('lng');
    if (lng) {
      i18n.changeLanguage(lng);
      localStorage.setItem("lng", lng);
    }
    if (document && document.scrollingElement && document.documentElement) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);
  const getRoutes = (allRoutes: any) =>
    allRoutes.map((route: any) => {
      
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<div>{i18n.t('loading')}</div>}>
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Presentation />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
