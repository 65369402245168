import { Link } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// translation
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const BuiltByDevelopers = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const { t } = useTranslation();
  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(https://drrv4375usi92.cloudfront.net/images/itpr-landing-content-creation-digital-marketing-desktop-image.webp)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      marginTop={0}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant={isMobile ? "h1Small" : "h1"} color="white" mb={1}>
            {t("content-creation.title")}
          </MKTypography>
          <MKTypography variant={isMobile ? "body2" : 'body1'} color="white" opacity={0.8} mb={2}>
            {t("content-creation.description")}
          </MKTypography>
          <MKTypography
            component={Link}
            to="/pages/landing-pages/solutions"
            rel="noreferrer"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            {t('read-more')} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
