// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// translation
import { useTranslation } from 'react-i18next';

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "../../../examples/Cards/CounterCards/DefaultCounterCard";
import { useMediaQuery } from "react-responsive";

const Counters = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 600 })
  return (
    <MKBox component="section" py={isMobile ? 0 : 3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={45}
              suffix="+"
              title={t('counters.clients.title')}
              description={t('counters.clients.description')}
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={360}
              suffix="+"
              title={t('counters.projects.title')}
              description={t('counters.projects.description')}
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={9}
              title={t('counters.experience.title')}
              description={t('counters.experience.description')}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
