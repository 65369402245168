// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import enTranslation from './locales/en/translation.json';
import rsTranslation from './locales/rs/translation.json';
import frTranslation from './locales/fr/translation.json';
import esTranslation from './locales/es/translation.json';
import itTranslation from './locales/it/translation.json';
import zhTranslation from './locales/zh/translation.json';
import ruTranslation from './locales/ru/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      rs: {
        translation: rsTranslation,
      },
      fr: {
        translation: frTranslation
      },
      it: {
        translation: itTranslation
      },
      es: {
        translation: esTranslation,
      },
      zh: {
        translation: zhTranslation,
      },
      ru: {
        translation: ruTranslation
      }
    },
    lng: localStorage.getItem("lng") || 'en', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already escapes strings
    },
  });

export default i18n;