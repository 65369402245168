// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// components
import MKBox from "../../../components/MKBox";

// examples
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../examples/Cards/BlogCards/CenteredBlogCard";

// translations
import { useTranslation } from "react-i18next";

function Information() {
  const { t } = useTranslation();
  return (
    <MKBox component="section" py={5}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="search"
                    title={t("about-us.seo.title")}
                    description={t("about-us.seo.description")}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="explore-outlined"
                    title={t("about-us.custom-strategy.title")}
                    description={t("about-us.custom-strategy.description")}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="equalizer"
                    title={t("about-us.advanced-analytics.title")}
                    description={t("about-us.advanced-analytics.description")}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="create"
                    title={t("about-us.creative-content.title")}
                    description={t("about-us.creative-content.description")}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://drrv4375usi92.cloudfront.net/images/itpr-marketing-agency-about-us-small-section.webp"
              title={t("about-us.about-us-chapter.title")}
              description={t("about-us.about-us-chapter.description")}
              action={{
                type: "internal",
                route: "/contact-us",
                label: t('contact-us'),
                color: "info",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
