// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";

// translation
import { useTranslation } from "react-i18next";

// responsive
import { useMediaQuery } from "react-responsive";

const Download = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { t } = useTranslation();
  return (
    <MKBox component="section" py={{ xs: 0, sm: 2 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={isMobile ? "https://drrv4375usi92.cloudfront.net/images/itprs-online-marketing-contact-us-mobile.webp" : "https://drrv4375usi92.cloudfront.net/images/itprs-online-marketing-contact-us.webp"}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height={ isMobile ? "40vh" : "inherit"}
          overflow="hidden"
          zIndex={1}
          opacity={0.3}
        />
        <Container sx={{ position: "relative", zIndex: 2, py: 12, paddingTop: isMobile ? '20px' : "96px", paddingBottom: isMobile ? 0 : "96px"}}>
          <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant={isMobile ? 'landingSecondSmall' : 'h3'} color="white">
              {t('contact-us-landing.title')}
            </MKTypography>
            <MKTypography variant={isMobile ? 'landingFirstSmall' : 'h3'} color="white" mb={1}>
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={8}>
              {t('contact-us-landing.description')}
            </MKTypography>
            <MKButton
              variant="gradient"
              color="info"
              size="large"
              component="a"
              href="/contact-us"
              sx={{ mb: 3 }}
            >
              {t('contact-us')}
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Download;
