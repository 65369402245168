// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 React components
import MKTypography from "./components/MKTypography";

// Images
import logoCT from "./assets/images/itpr-digital-marketing-agency-logo.webp";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "ITPR Agency Ltd",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/profile.php?id=61554164660727",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/itpr-agency/",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/agencyitpr/",
    },
  ],
  menus: [
    {
      name: "footer-menu.company",
      items: [
        { name: "main-menu.about-us.title", route: "/pages/landing-pages/about-us" },
      ],
    },
    {
      name: "footer-menu.resources",
      items: [
        { name: "blog", href: "https://blog.com/" },
      ],
    },
    {
      name: "footer-menu.help-and-support",
      items: [
        { name: "contact-us", route: "/contact-us" },
      ],
    },
    {
      name: "footer-menu.legal",
      items: [
        { name: "footer-menu.terms-and-conditions", route: "/pages/landing-pages/about-us" },
        { name: "footer-menu.privacy-policy", route: "/pages/landing-pages/about-us", },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} ITPR. by{" "}
      <MKTypography
        component="a"
        href="https://www.agencyitpr.com/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        ITPR Agency
      </MKTypography>
      .
    </MKTypography>
  ),
};
