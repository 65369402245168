/** 
  All of the routes are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

import AboutUs from "./pages/AboutUs";
import AdvertisingCampaignsComponent from "./pages/AdvertisingCampaignsComponent";
import EventOrganizationComponent from "./pages/EventOrganizationComponent";
import ManagementConsultingComponent from "./pages/ManagementConsultingComponent";
import PRComponent from "./pages/PRComponent";
import PackagingComponent from "./pages/PackagingComponent";
import PromoMaterialComponent from "./pages/PromoMaterialComponent";
import SEOComponent from "./pages/SEOComponent";
import SolutionsComponent from "./pages/Solutions";
import StrategicMarketingComponent from "./pages/StrategicMarketingComponent";

const routes = [
  {
    name: "main-menu.services.title",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "main-menu.services.menu-items.SEO.title",
            route: "/pages/landing-pages/seo",
            component: <SEOComponent />,
          },
          {
            name: "main-menu.services.menu-items.strategic-marketing.title",
            route: "/pages/landing-pages/strategic-marketing",
            component: <StrategicMarketingComponent />,
          },
          {
            name: "main-menu.services.menu-items.advertising-campaigns.title",
            route: "/pages/landing-pages/advertising-campaigns",
            component: <AdvertisingCampaignsComponent />,
          },
          {
            name: "main-menu.services.menu-items.management-consulting.title",
            route: "/pages/landing-pages/management-consulting",
            component: <ManagementConsultingComponent />,
          },
          {
            name: "main-menu.services.menu-items.pr.title",
            route: "/pages/landing-pages/pr",
            component: <PRComponent />,
          },
          {
            name: "main-menu.services.menu-items.event-organization.title",
            route: "/pages/landing-pages/event-organization",
            component: <EventOrganizationComponent />,
          },
          {
            name: "main-menu.services.menu-items.packaging.title",
            route: "/pages/landing-pages/packaging",
            component: <PackagingComponent />,
          },
          {
            name: "main-menu.services.menu-items.promo-material.title",
            route: "/pages/landing-pages/promo-material",
            component: <PromoMaterialComponent />,
          },
        ],
      },
    ],
  },
  {
    name: "main-menu.solutions.title",
    route: "/pages/landing-pages/solutions",
    component: <SolutionsComponent />
  },
  {
    name: "main-menu.about-us.title",
    route: "/pages/landing-pages/about-us",
    component: <AboutUs />
  },
];

export default routes;
