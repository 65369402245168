import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, IconButton, Icon } from '@mui/material';
import { FlagIcon } from 'react-flag-kit';
import { useMediaQuery } from 'react-responsive';

// componets
import MKTypography from '../../components/MKTypography';

// translation
import { useTranslation } from 'react-i18next'; // Import the hook

const languages = [
  { code: 'en', name: 'English', flag: 'GB' },
  { code: 'rs', name: 'Serbian', flag: 'RS' },
  { code: 'fr', name: 'French', flag: 'FR' },
  { code: 'it', name: 'Italian', flag: 'IT' },
  { code: 'es', name: 'Spanish', flag: 'ES' },
  { code: 'ru', name: 'Russian', flag: 'RU' },
  { code: 'zh', name: 'Chinese', flag: 'CN' }
];

const LanguagePicker = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const { i18n } = useTranslation();
  
  useEffect(() => {
    const lng = localStorage.getItem("lng");
    if (lng) setSelectedLanguage(lng);
  }, [])

  const handleLanguageChange = (code) => {
    setSelectedLanguage(code);
    i18n.changeLanguage(code);
    localStorage.setItem("lng", code);
    setAnchorEl(null);
  };

  const selectedFlag = languages.find((lang) => lang.code === selectedLanguage)?.flag;

  return (
    <div style={{ position: isMobile ? 'fixed' : '', top: isMobile ? '90vh' : '', right: isMobile ? '0' : ''}}>
      {/* Button for opening language menu */}
      <IconButton 
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }} 
        color="inherit"
      >
        <FlagIcon code={selectedFlag} style={{ marginRight: '8px', width: '27px', height: '20px' }} />
        <MKTypography>
          <Icon sx={{ fontWeight: "normal", verticalAlign: "middle", color: isMobile ? "#1A73E8" : "#fff" }}>
            {"keyboard_arrow_down"}
          </Icon>
        </MKTypography>
      </IconButton>

      {/* Language selection menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {languages.map((language) => (
          <MenuItem key={language.code} onClick={() => handleLanguageChange(language.code)}>
            <FlagIcon code={language.flag} style={{ marginRight: '8px', width: '27px', height: '20px' }} />
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguagePicker;
