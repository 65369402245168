import React from 'react';
import Card from "@mui/material/Card";

// components
import ITPRProductSections from '../ITPRProductSections';

// responsive 
import { useMediaQuery } from 'react-responsive';

const ITPRProductContent = ({ sections }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <Card
      
      sx={{
        p: 2,
        mx: { xs: 2, lg: 3 },
        mt: -8,
        mb: 4,
        backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
        backdropFilter: "saturate(200%) blur(30px)",
        boxShadow: ({ boxShadows: { xxl } }) => xxl,
        paddingTop: '10px',
        paddingBottom: '35px',
        marginTop: isMobile ? 8 : ''
      }}
    >
      {sections.map((section, index) => (
        <ITPRProductSections
          key={index}
          title={section.title}
          sectionText={section.description}
        />
      ))}
    </Card>
  );
}

export default ITPRProductContent;