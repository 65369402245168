import React from 'react';
import { InfinitySpin } from 'react-loader-spinner';

const Loading = () => (
  <div
    style={{
      width: "100%",
      height: "100vh"
    }}
  >
    <div style={{
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%"
    }}>
      <InfinitySpin
        height="180"
        width="180"
        color="#1a73e8"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  </div>
)

export default Loading;