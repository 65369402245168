import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Grid from "@mui/material/Grid";
import { useTranslation } from 'react-i18next';
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import ITPRProductContent from '../../components/ITPRProductContent';
import footerRoutes from "../../footer.routes";
import routes from "../../routes";
import { Helmet } from 'react-helmet';

function PRComponent() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const sectionsData = [
    { title: t('prcomponent.componentSections1.title'), description: t('prcomponent.componentSections1.description') },
    { title: t('prcomponent.componentSections2.title'), description: t('prcomponent.componentSections2.description') },
    { title: t('prcomponent.componentSections3.title'), description: t('prcomponent.componentSections3.description') },
    { title: t('prcomponent.componentSections4.title'), description: t('prcomponent.componentSections4.description') },
    { title: t('prcomponent.componentSections5.title'), description: t('prcomponent.componentSections5.description') },
    { title: t('prcomponent.componentSections6.title'), description: t('prcomponent.componentSections6.description') },
    { title: t('prcomponent.componentSections7.title'), description: t('prcomponent.componentSections7.description') },
    { title: t('prcomponent.componentSections8.title'), description: t('prcomponent.componentSections8.description') },
    { title: t('prcomponent.componentSections9.title'), description: t('prcomponent.componentSections9.description') },
    { title: t('prcomponent.componentSections10.title'), description: t('prcomponent.componentSections10.description') },
    { title: t('prcomponent.componentSections11.title'), description: t('prcomponent.componentSections11.description') }
  ];

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{t('prcomponent.herotitle')}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MKBox variant="gradient" bgColor="mixed" shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact-us",
            label: t('contact-us'),
            color: "info",
          }}
          transparent
          relative
          light
          center
        />
        <div class="bg"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div>
      </MKBox>

      <MKBox
        sx={{
          width: '100vw',
          height: isMobile ? '65vh' : '50vh',
          display: 'flex',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <MKBox
              className="fade-in-left"
              sx={{
                backgroundImage: "url(https://drrv4375usi92.cloudfront.net/images/itpr-digital-marketing-agency-PR-services.webp)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: isMobile ? "40vh" : '100%',
                width: '100%',
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: isMobile ? 'start' : 'center',
              justifyContent: 'center',
              height: isMobile ? "35vh" : '100%',
              padding: isMobile ? "10px" : "0",
            }}
          >
            <MKBox textAlign="center" p={3} sx={{ display: 'flex', flexDirection: "column", marginTop: isMobile ? "40px" : "0" }}>
              <MKTypography
                className="fade-in-right"
                variant={isMobile ? "h5" : "h3"}
                color="info"
                fontWeight="bold"
              >
                {t('prcomponent.herotitle')}
              </MKTypography>
              <MKTypography
                className="fade-in-right"
                variant={isMobile ? "h6" : "h6"}
                color="textSecondary"
                mt={2}
              >
                {t('prcomponent.herosubtitle')}
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>

      <ITPRProductContent sections={sectionsData} />

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PRComponent;