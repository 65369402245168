import { useMediaQuery } from 'react-responsive';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// translation
import { useTranslation } from 'react-i18next';

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";

// Presentation page sections
import Counters from "./sections/Counters";
import Information from "./sections/Information";
import Testimonials from "./sections/Testimonials";
import Download from "./sections/Download";

// Presentation page components
import BuiltByDevelopers from "./components/BuiltByDevelopers";

// routes
import footerRoutes from "../../footer.routes";
import routes from "../../routes";

import {Helmet} from "react-helmet";

function Presentation() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('head-text-1')}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MKBox variant="gradient" bgColor="mixed" shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact-us",
            label: t('contact-us'),
            color: "info",
          }}
          transparent
          relative
          light
          center
        />
              <div class="bg"></div>
              <div class="bg bg2"></div>
              <div class="bg bg3"></div>
      </MKBox>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: 
            isMobile ? 
              "url(https://drrv4375usi92.cloudfront.net/images/itpr-digital-agency-astro-landing-mobile.gif)" : "url(https://drrv4375usi92.cloudfront.net/images/itpr-markting-agency-landing-astro-big.webp)",
          backgroundSize: "cover",
          backgroundPosition: isMobile ? "center" : "top",
          display: "grid",
          placeItems: isMobile ? "end" : "center",
          paddingBottom: isMobile ? "89px" : "0",
        }}
        xs={0}
      >
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="start" mx="auto">
            <MKTypography
              variant={isMobile ? "landingFirstNewSmall" : "landingFirstBig"}
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 8 }}
              mt={1}
              textTransform="uppercase"
              backgroundColor="rgba(26,115,232,0.50)"
              fontWeight="bold"
              paddingTop="5px"
              paddingBottom="5px"
              borderRadius="10px"
              className="slide-zoom-fade"
            >
              {t('head-text-1')}
            </MKTypography>
            <MKTypography
              variant={isMobile ? "solutionsSecondSmall" : "landingSecondBig"}
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 10 }}
              mt={1}
              textTransform="uppercase"
              fontWeight="bold"
              paddingTop="2px"
              paddingBottom="20px"
              borderRadius="10px"
              className="slide-zoom-fade"
            >
              {t('head-text-2')}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <Container sx={{ mt: 6 }} style={{marginTop: 0, marginBottom: 30 }}>
          <BuiltByDevelopers />
        </Container>
        <Testimonials />
        <Download />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
