// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../examples/Footers/DefaultFooter";

// About Us page sections
import Information from "./sections/Information";
import Team from "./sections/Team";
import Newsletter from "./sections/Newsletter";

// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";

// translation
import { useTranslation } from "react-i18next";
import AboutUsSections from "../../examples/AboutUsSections";
import { Helmet } from "react-helmet";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{t('about-us.main-title')}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MKBox variant="gradient" bgColor="mixed" shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact-us",
            label: t('contact-us'),
            color: "info",
          }}
          transparent
          relative
          light
          center
        />
      </MKBox>
      <MKBox
        minHeight="70vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(https://drrv4375usi92.cloudfront.net/images/itpr-digital-marketing-services-about-us-hero.webp)`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container className="slide-zoom-fade">
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {t('about-us.title')}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {t('about-us.subtitle')}
            </MKTypography>
            <MKButton 
              color={"info"}
              href="/contact-us"
            >
              {t('contact-us')}
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          paddingTop: '20px',
          paddingBottom: '35px'
        }}
      >
        <AboutUsSections
          title={t("about-us.main-title")}
          sectionText={t("about-us.section1")}
        />  
        <AboutUsSections 
          sectionText={t("about-us.section2")}
        />
        <AboutUsSections 
          title={t("about-us.subtitle-first")}
          sectionText={t("about-us.section3")}
        />
        <AboutUsSections 
          sectionText={t("about-us.section4")}
        />
        <AboutUsSections 
          sectionText={t("about-us.section5")}
        />
      </Card>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        {/* <Team /> */}
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
