// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import { useMediaQuery } from "react-responsive";


// Images

const ITPRProductSections = ({ title, sectionText }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
      <Container paddingTop={0} style={{ paddingTop: 0 }} sx={{ paddingTop: 0 }} >
        <Grid container item xs={12} justifyContent="center" mx="auto" paddingTop={0} style={{ paddingBottom: 0, paddingTop: 0}}>
          <Grid container justifyContent="center" py={6} paddingTop="15px" paddingBottom="10px">
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent={!isMobile ? "center" : "space-between"} alignItems="center" mb={1}>
                <MKTypography variant="contentTitle" className="fade-in-card" >{title}</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text" className="fade-in-card" >
                {sectionText}
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
  );
}

export default ITPRProductSections;